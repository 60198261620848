import { Observable } from 'rxjs';
import { LoggedInUser } from '../models/auth.model';
import { FacebookService } from './facebook.service';
import { LoginRequestData } from '../components/modal-login/modal-login.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UnsubscribeList } from '../../search/models/common.models';
import { environment } from '@env';

@Injectable({ providedIn: 'root' })
export class AuthService {
    constructor(
        private http: HttpClient,
        private facebookService: FacebookService
    ) {}

    authenticateUser(
        type: string,
        data: LoginRequestData
    ): Observable<LoggedInUser> {
        return this.http.post<LoggedInUser>(
            `${environment.GATE_URL}/reg/${type}`,
            data
        );
    }

    forgotPassword(data: { email: string }): Observable<{}> {
        return this.http.put<{}>(`${environment.GATE_URL}/user/reset_pw`, data);
    }

    checkPasswordToken(data: { email: string; token: string }): Observable<{}> {
        return this.http.get(`${environment.GATE_URL}/user/reset_pw`, {
            params: data
        });
    }

    resetPassword(data: {
        email: string;
        pw: string;
        token: string;
    }): Observable<{}> {
        return this.http.put(`${environment.GATE_URL}/user/set_pw`, data, {
            responseType: 'text' as 'json'
        });
    }

    logout(): Observable<{}> {
        this.facebookService.logout();
        return this.http.post<{}>(`${environment.GATE_URL}/user/logout`, null);
    }

    unsubscribe(data: {
        list: string;
        token: string;
    }): Observable<UnsubscribeList[]> {
        const config = {
            headers: {
                withCredentialsRemove: 'true'
            }
        };
        return this.http.post<UnsubscribeList[]>(
            `${environment.GATE_URL_2}/users/v1/unsubscribe`,
            data,
            config
        );
    }
}
